import React from 'react';
import "../../components/App/App.scss";
import Hero from "../../components/hero/hero";
import ContactUs from '../../components/contactUs/contactUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import panupskyscraper from '../../img/panupskyscraper.png';
import { boomiPageData } from './boomiPageData';
import JumboImageCardRight from '../../components/jumboImageCardRight/jumboImageCardRight';
import JumboImageTopLeft from '../../components/jumboImageTopLeft/jumboImageTopLeft';
import JumboTextRight from '../../components/jumboTextRight/jumboTextRight';
import JumboAltHeightCards2 from '../../components/jumboAltHeightCards2/jumboAltHeightCards2';
import Helmet from 'react-helmet';

export default function Boomi() {
    
    return (

        <Container fluid className="hero-container">
            <Helmet>
                <title>Boomi</title>
                <meta 
                    name="description"
                    content="Your data has never been more important. Drive productivity, reduce costs and increase business performance with full data integration and rapid analysis."
                 />
                 <meta 
                    name="keywords"
                    content="ipaas, boomi, enspire, technologies, integration resources, integration platform as a service, connections, intelligent engineering, 
                    cloud computing, integration, automate workflows, self-service connectivity, integration architecture, integration support, integration development, api management"
                 />
            </Helmet>
            {boomiPageData.map(data => (
              <Hero
                getDemoButton={data.hero.getDemoButton}
                text7={data.hero.text7}
                rectangle520={data.hero.rectangle520}
                text5={data.hero.text5}
                text6={data.hero.text6}
              />
            ))}


            {boomiPageData.map(data => (
                <JumboTextRight
                    image = {data.jumboTextRight.image}
                    rectangle530 ={data.jumboTextRight.rectangle530}
                    rectangle531 = {panupskyscraper}
                    text = {data.jumboTextRight.text}
                    text9 = {data.jumboTextRight.text9}
                    text10 = {data.jumboTextRight.text10}
                    text11 = {data.jumboTextRight.text11}
                    text12 = {data.jumboTextRight.text12}
                    text13 = {data.jumboTextRight.text13}
                    maskGroup = {data.jumboTextRight.maskGroup}
                />
            ))}

            {boomiPageData.map(data => (
                <ContactUs
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
            ))}

            {boomiPageData.map(data => (
                <JumboImageCardRight
                    image = {data.jumboImageCardRight.image}
                    rectangle530 ={data.jumboImageCardRight.rectangle530}
                    rectangle531 = {data.jumboImageCardRight.rectangle531}
                    text8 = {data.jumboImageCardRight.text14}
                    text = {data.jumboImageCardRight.title}
                    text9 = {data.jumboImageCardRight.text9}
                    text10 = {data.jumboImageCardRight.text10}
                    maskGroup = {data.jumboImageCardRight.maskGroup}
                    title1 = {data.jumboImageCardRight.cardDeck.title1}
                    title2 = {data.jumboImageCardRight.cardDeck.title2}
                    title3 = {data.jumboImageCardRight.cardDeck.title3}
                    title4 = {data.jumboImageCardRight.cardDeck.title4}
                    text1 = {data.jumboImageCardRight.cardDeck.text1}
                    text2 = {data.jumboImageCardRight.cardDeck.text2}
                    text3 = {data.jumboImageCardRight.cardDeck.text3}
                    text4 = {data.jumboImageCardRight.cardDeck.text4}
                />
            ))}


            <div style={{backgroundImage: 'url(rectangle)',backgroundSize: '100%', width:'100%', backgroundAttachment: 'fixed'}}>
            {boomiPageData.map(data => (
                <JumboImageTopLeft
                    image = {data.jumboImageTopLeft.image}
                    rectangle530 ={data.jumboImageTopLeft.rectangle530}
                    rectangle531 = {panupskyscraper}
                    text = {data.jumboImageTopLeft.text}
                    text9 = {data.jumboImageTopLeft.text9}
                    text10 = {data.jumboImageTopLeft.text10}
                    text11 = {data.jumboImageTopLeft.text11}
                    text12 = {data.jumboImageTopLeft.text12}
                    text13 = {data.jumboImageTopLeft.text13}
                    maskGroup = {data.jumboImageTopLeft.maskGroup}
                    title1 = {data.jumboImageTopLeft.cardDeck.title1}
                    title2 = {data.jumboImageTopLeft.cardDeck.title2}
                    title3 = {data.jumboImageTopLeft.cardDeck.title3}
                    title4 = {data.jumboImageTopLeft.cardDeck.title4}
                    text1 = {data.jumboImageTopLeft.cardDeck.text1}
                    text2 = {data.jumboImageTopLeft.cardDeck.text2}
                    text3 = {data.jumboImageTopLeft.cardDeck.text3}
                    text4 = {data.jumboImageTopLeft.cardDeck.text4}
                />
            ))}

            {boomiPageData.map(data => (
                    <JumboAltHeightCards2
                        image2={data.jumboAltHeight.image2}
                        rectangle5304={data.jumboAltHeight.rectangle5304}
                        text20={data.jumboAltHeight.text20}
                        title={data.jumboAltHeight.title}
                        stripe={data.jumboAltHeight.stripe}
                        title1 = {data.jumboAltHeight.cardDeck.title1}
                        title2 = {data.jumboAltHeight.cardDeck.title2}
                        title3 = {data.jumboAltHeight.cardDeck.title3}
                        title4 = {data.jumboAltHeight.cardDeck.title4}
                        title5 = {data.jumboAltHeight.cardDeck.title5}
                        text1 = {data.jumboAltHeight.cardDeck.text1}
                        text2 = {data.jumboAltHeight.cardDeck.text2}
                        text3 = {data.jumboAltHeight.cardDeck.text3}
                        text4 = {data.jumboAltHeight.cardDeck.text4}
                        text5 = {data.jumboAltHeight.cardDeck.text5}
                        text9 = {data.jumboAltHeight.text9}
                    />
                ))}

                {boomiPageData.map(data => (
                <ContactUs 
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
                ))}
                 
            </div>
        </Container>
    );
}
