import React from 'react';
import './jumbotronImage.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col } from 'react-bootstrap'

export default function JumbotronImage(props) {
    const { image, rectangle530, rectangle531, text, text9, text10, maskGroup } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage">
             <Image className="left-bg-stripe" src={image} />
                <Container>
                    <TitleStripe 
                        title={text}
                        stripe={rectangle530}
                    />
                
                    <Row className="description">
                        <Col xs={6} md={5} lg={5}>{text9}</Col>
                        <Col xs={4} md={5} lg={5}>{text10}</Col>
                    </Row>

                    <Row >
                        <Col style={{position:'relative'}}>
                            <Image fluid className="mask-group" src={maskGroup} />
                            <Image fluid className="rectangle-531" src={rectangle531} />
                            
                        </Col>
                    </Row>
                </Container>
    </Jumbotron>
    )
}