export const aboutPageData = [
    {
        hero:{
            getDemoButton: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/buttons-orange@2x.svg",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-2@2x.svg",
            text5: "About Us",
            text6: <h3>Connecting the right people with the right data at the right time <br /> <br /> <h4>We are systems integrators, consultants, experts and advisors driven to deliver connectivity to help your business run better and grow faster.</h4></h3>
         },
        jumbotronImageCardFront:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: <>Providing the Best Solutions<br />for Your Business Challenges</>,
            text9: "Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, we have the right solution for you.",
            text10: "We provide high quality work and customized solutions to ensure successful outcomes for your people and your business.",
            rectangle531: " ../../img/panupskyscraper",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg"
        },
        content2: {
            rectangle5302: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-1@2x.svg",
            text14: "Right strategy, right time integration",
            text15: "Get Connected and Stay Connected to Drive Your Business Forward",
            text16: "As a Boomi Certified Partner, Enspire Technologies brings broad and deep expertise in the customization and implementation of the best integration platform on the market. We deliver intelligent engineering with white glove customer service in delivery of the full Boomi technical stack.",
            text9: "Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, we have the right solution for you.",
            text10: "We provide high quality work and customized solutions to ensure successful outcomes for your people and your business.",
            rectangle531: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-531@1x.png",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg",
            card1:{
                title:<>Boomi<br />Connect Now</>,
                description:"Get the unmatched integration capabilities of Boomi with the ease of self-service."
            },
            card2:{
                title:"Boomi Flow",
                description:"Create a guided journey for your customers with simple workflow automation and mobile application development using a low-code development solution that allows your business to be more agile and deliver a better customer experience."
            }
        },
        jumboThreeCards:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: <>Providing the Best Solutions<br />for Your Business Challenges</>,
            text9: "Enspire Technolgies is a global systems integrator dedicated to being your partner in connecting, streamlining, and growing your business. We create value through our depth of technology services, highly skilled consultants, close collaboration, and creative solutions. We specialize in breaking down silos within your business and connecting everything.",
            text10: "As a Boomi Certified Partner, Enspire Technologies brings broad and deep expertise in the customization and implementation of the best integration platform on the market. We deliver intelligent engineering with white glove customer service in delivery of the full Boomi technical stack.",
            rectangle531: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-531@1x.png",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg",
            title: "Our Expertise",
            cardDeck: {
                title1:"Integration",
                title2:"Cloud Computing",
                title3: "Intelligent Engineering",
                text1: "We break down silos and connect your critical business processes to meet your business goals. We have deep experience with a wide range of tools and technologies.",
                text2: "Whether you’re looking for cutting edge cloud architecture or serverless application architecture, we meet you where you are and guide you on your journey.",
                text3: "We listen to your needs, closely collaborate with your teams, and develop the best solution for your problem. We believe there are no ‘one size fits all’ solutions. ",
            }
        },
        jumboPresidentProfile:{
            title: "Meet Our President and Founder",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            rectangle5303: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-2@2x.svg",
            text18: <>Cloud migration and hybridization<br />for a modern business</>,
            rightStrategyPagesShadowRight: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/right-strategy-pages-shadow-right@1x.svg",
            
        },
        contactUs2Props: {
            overlapGroup3: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/black-orange-no@1x.svg",
            text11: "Contact us for a demo",
            text21: "Discover the how Enspire Technologies’ technical experts will help you transform your business.",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-1@2x.svg"
        },
        whyEnspire: {
            image2: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle5304: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-1@2x.svg",
            title: <>Why Enspire<br />Technologies?</>,
            stripe: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            cardDeck: {
                title1:<>Boomi<br />Connect Now</>,
                title2:"Boomi Flow",
                title3: <>Boomi<br />Integration</>,
                title4: <>Boomi API<br />Management</>,
                text1: "Get the unmatched integration capabilities of Boomi with the ease of self-service.",
                text2: "Create a guided journey for your customers with simple workflow automation and mobile application development using a low-code development solution that allows your business to be more agile and deliver a better customer experience.",
                text3: "Connecting your critical business processes is crucial. Accelerate productivity, results, and ROI with the streamlined processes and simplified interactions made possible with unified data integration.",
                text4: "Your connected business strategy is not complete without comprehensive end-to-end API management and integration. Boomi API Management solves the business problem of strategically designing, tracking, and scaling APIs enterprise-wide."
            }
        }
    }
];