import React from 'react';
import './jumboImageCardFront.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col, Card } from 'react-bootstrap'

export default function JumbotronImageCardFront(props) {
    const { image, rectangle530, rectangle531, text, text9, maskGroup } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage" style={{marginBottom:"8rem"}}>
             <Image className="left-bg-stripe" src={image} />
                <Container>
                    <TitleStripe 
                        title={text}
                        stripe={rectangle530}
                    />
                    
                
                    <Row className="description">
                        <Col xs={6} md={5} lg={5}>{text9}</Col>
                        <Col xs={4} md={5} lg={5}></Col>
                    </Row>

                    <Row >
                        <Col style={{position:'relative'}}>
                            <Image fluid className="panup" src={rectangle531} />
                            <Image fluid className="mask-group-2" src={maskGroup} />
                            <Card className="cardFront">
                                <Card.Body>
                                    <Card.Title>Your success is our success.</Card.Title>
                                    <Card.Text>We are a Dell Boomi certified partner with expertise in the full Dell Boomi technology stack, and architected Boomi Connect Now.  We also have deep experience implementing Amazon Web Services (AWS) to modernize your business processes.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
    </Jumbotron>
    )
}