import React from 'react';
import TitleStripe from '../titleStripe/titleStripe';
import { CardDeck, Card, Jumbotron, Image, Container, Row } from 'react-bootstrap';
import './jumboAltHeightCards2.scss'
import battlestation from '../../img/battlestation.png';
import stickers from '../../img/stickers.png';
import ovum from '../../img/ovum.png';
import dashboard from '../../img/dashboard.png';
import coderfarm from '../../img/coderfarm.png';

export default function JumboAltHeightCards2(props){

    const { image2, title, stripe, title1, title2, title3, title4, title5, text1, text2, text3, text4, text5, text9 } = props;

    return(
        <Jumbotron fluid className="why-enspire-tec-ologies-section">
            <Image className="image-2" src={image2} />
            <Container >

                <TitleStripe 
                    title={title} 
                    stripe={stripe}
                />

                <div className="description">
                    {text9}
                </div>
                
                <CardDeck style={{color:"white", width:"100%"}}>
                    <Card style={{background:'none'}}>
                        <Card.Img variant="top" src={coderfarm} />
                        <Card.Body>
                            <Card.Title>{title1}</Card.Title>
                            <Card.Text>{text1}</Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{background:'none'}}>
                        <Card.Img variant="top" src={dashboard} />
                        <Card.Body>
                            <Card.Title>{title2}</Card.Title>
                            <Card.Text>{text2}</Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{background:'none'}}>
                        <Card.Img variant="top" src={ovum} />
                        <Card.Body>
                            <Card.Title>{title3}</Card.Title>
                            <Card.Text>{text3}</Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{background:'none'}}>
                    <Card.Img variant="top" src={stickers} />
                        <Card.Body>
                            <Card.Title>{title4}</Card.Title>
                            <Card.Text>{text4}</Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{background:'none'}}>
                    <Card.Img variant="top" src={battlestation} />
                        <Card.Body>
                            <Card.Title>{title5}</Card.Title>
                            <Card.Text>{text5}</Card.Text>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>
        </Jumbotron>
    );
}