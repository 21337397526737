import React from 'react';
import Cards from '../Scrollcards/cards';
import TitleStripe from '../titleStripe/titleStripe';
import { CardDeck, Card, Jumbotron, Row, Image, Container } from 'react-bootstrap';
import './jumboAltHeightCards.scss'

export default function JumboAltHeightCards(props){

    const { image2, title, stripe, title1, title2, title3, title4, text1, text2, text3, text4 } = props;

    return(
        <Jumbotron fluid className="why-enspire-tec-ologies-section">
            <Image className="image-2" src={image2} />
            <Container >

                <TitleStripe 
                    title={title} 
                    stripe={stripe}
                />
                
                <CardDeck style={{color:"white", width:"100%"}}>
                    <Card style={{background:'none'}}>
                        <Card.Img variant="top" src="https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-10@2x.png" />
                        <Card.Body>
                            <Card.Title>{title1}</Card.Title>
                            <Card.Text>{text1}</Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{background:'none'}}>
                        <Card.Img variant="top" src="https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-10-1@2x.png" />
                        <Card.Body>
                            <Card.Title>{title2}</Card.Title>
                            <Card.Text>{text2}</Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{background:'none'}}>
                        <Card.Img variant="top" src="https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-10-2@2x.png" />
                        <Card.Body>
                            <Card.Title>{title3}</Card.Title>
                            <Card.Text>{text3}</Card.Text>
                        </Card.Body>
                    </Card>

                    <Card style={{background:'none'}}>
                    <Card.Img variant="top" src="https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-10-3@2x.png" />
                        <Card.Body>
                            <Card.Title>{title4}</Card.Title>
                            <Card.Text>{text4}</Card.Text>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>
        </Jumbotron>
    );
}