import React from 'react';
import './button.scss';
import { Container, Row, Button } from 'react-bootstrap';

export default function Buttons (){

    return(
        <Container fluid>
            <Row>
                <Button size="lg" href="/contact" className="button">
                        Contact Us
                        <svg class="rectangle-520-1" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                            <path d="M29.4126 0.220751L41.8541 0.220802L0.220802 41.8541L0.220794 29.4125L29.4126 0.220751Z" fill="#fff"></path>
                        </svg>
                </Button>
            </Row>
        </Container>
    )
}