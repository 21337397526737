import React from 'react';
import './jumboTextRight.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col } from 'react-bootstrap'
import spheres from '../../img/spheres.jpg';

export default function JumboTextRight(props) {
    const { image, rectangle530, rectangle531, text, text9, text10, text11, text12, text13, maskGroup } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage">
            <Image className="left-bg-stripe" src={image} />
            <Container style={{position:'relative'}}>
                <Row>
                    <Col xs={6} m={6} lg={6}>
                            <TitleStripe 
                                title={text}
                                stripe={rectangle530}
                            />
                            
                            <Row className="description">
                                <Col >{text9}</Col>
                            </Row>

                            <Row >
                                <Col style={{position:'relative'}}>
                                    <Image fluid src={spheres} />
                                </Col>
                            </Row>
                    </Col>
                    <Col xs={1} m={2} lg={2} />
                    <Col xs={4} m={4} lg={4}>
                            <Image fluid className="orangesquare" src={maskGroup} />
                            <Row style={{borderTop:'orange 1px solid'}}>
                                <h3>Self-Service Connectivity</h3>
                                <p className="description">{text10}</p>
                            </Row>
                            <Row style={{borderTop:'orange 1px solid'}}>
                                <h3>Workflow Automation</h3>
                                <p className="description">{text11}</p>
                            </Row>
                            <Row style={{borderTop:'orange 1px solid'}}>
                                <h3>Integration Architecture, Development and Support</h3>
                                <p className="description">{text12}</p>
                            </Row>
                            <Row style={{borderTop:'orange 1px solid'}}>
                                <h3>API Management</h3>
                                <p className="description">{text13}</p>
                            </Row>
                    </Col>
                </Row>
            </Container>
    </Jumbotron>
    )
}