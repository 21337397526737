import React from 'react';
import './jumboDemoImage.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col } from 'react-bootstrap'
import vertskyscraper from '../../img/vertskyscraper.png';
import Buttons from '../../components/button/button';

export default function JumboDemoImage(props) {
    const { image, rectangle530, rectangle531, text, text9, text10 } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage">
             <Image className="left-bg-stripe" src={image} />
                <Container>
                    <Row>

                        <Col className="text-wrap">
                            <TitleStripe 
                                title={text}
                                stripe={rectangle530}
                            />
                            <Row className="description">
                                <Col>{text10}</Col>
                            </Row>
                            <Row>
                                <Buttons />
                            </Row>
                        </Col>

                        <Col xs={1} md={1} lg={1} />

                        <Col style={{position:'relative'}}>
                            <Image fluid rounded src={vertskyscraper} />
                        </Col>
                    </Row>
                </Container>
    </Jumbotron>
    )
}
