import React from 'react';
import Cards from '../Scrollcards/cards';
import TitleStripe from '../titleStripe/titleStripe';
import { CardDeck, Container, Jumbotron } from 'react-bootstrap';
import './jumboScrollCards.scss';
import jumboScrollCardsData from './jumboScrollCardsData';

export default function JumboScrollCards(props){

    const { title, rectangle530 } = props;

    return(
        <Jumbotron className="jumboFourCards">
            <Container>
                <TitleStripe 
                    title={title}
                    stripe={rectangle530}
                />

                {jumboScrollCardsData.map(data => (
                <Cards
                title1 = {data.cards.title1}
                title2 = {data.cards.title2}
                title3 = {data.cards.title3}
                title4 = {data.cards.title4}
                text1 = {data.cards.text1}
                text2 = {data.cards.text2}
                text3 = {data.cards.text3}
                text4 = {data.cards.text4}
                image1 = {data.cards.image1}
                image2 = {data.cards.image2}
                image3 = {data.cards.image3}
                image4 = {data.cards.image4}
                />            
                ))}
            </Container>
        </Jumbotron>
    )
}