import React from "react";
import "./footer.scss";
import { Nav, Navbar, Image, Row, Col } from 'react-bootstrap';
import logo from '../../img/logo.png';
// import pin from '../../img/pin.png';
// import phone from '../../img/phone.png';
import twitter from '../../img/twitter.png';
import mail from '../../img/Mail.png';
import linkedin from '../../img/linkedin.png';


export default function Footer() {

    return (
      <Navbar className="footer2"  >
        <Row className="footer-container">
          <Col className="footer-seo"  >
              <Col><Image className="footer-logo" src={logo} /></Col>
              {/* <Col><Navbar.Brand style={{color:"#EE8F39", fontWeight:"bold", margin:"0px"}}  >
                <img
                  alt=""
                  src={pin}
                  width="16"
                  height="20"
                  className="d-inline-block align-top"
                />{' '}
                </Navbar.Brand></Col>
              <Col><Navbar.Brand style={{color:"#EE8F39", fontWeight:"bold", margin:"0px"}}  >
              <img
                  alt=""
                  src={phone}
                  width="19"
                  height="19"
                  className="d-inline-block align-top"
                />{' '}
                </Navbar.Brand></Col> */}
          </Col>

          <Col className="footer-col" style={{boxShadow: 'inset 1px 0px 0px rgba(255, 255, 255, 0.1)'}}  xs={12} s={6} m={3} lg={4}>
            <Navbar className="flex-column" style={{alignItems:'flex-start'}}>
              <Nav.Item >
                <Nav.Link href='/about' style={{color:'#747777'}}>Socials</Nav.Link>
              </Nav.Item> 
              <Nav.Item >
                <Nav.Link href='https://twitter.com/EnspireTech' target="_blank">
                  <img
                  alt=""
                  src={twitter}
                  width="19"
                  height="19"
                  className="d-inline-block align-top"
                  />{' '}
                  Twitter
                </Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='https://www.linkedin.com/company/enspiretechnologies/' target="_blank">
                  <img
                    alt=""
                    src={linkedin}
                    width="19"
                    height="19"
                    className="d-inline-block align-top"
                  />{' '}
                  Linkedin</Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='email:info@enspire-tech.com' >
                  <img
                    alt=""
                    src={mail}
                    width="19"
                    height="19"
                    className="d-inline-block align-top"
                  />{' '}
                  Gmail</Nav.Link>
              </Nav.Item>
            </Navbar>
          </Col>
        </Row>

        <Col className="footer-col-mobile" target="_blank">
            <Navbar  style={{alignItems:'flex-start'}}>
              <Nav.Item >
                <Nav.Link href='https://twitter.com/EnspireTech' >
                  <img
                  alt=""
                  src={twitter}
                  width="19"
                  height="19"
                  className="d-inline-block align-top"
                  />{' '}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='https://www.linkedin.com/company/enspiretechnologies/' target="_blank">
                  <img
                    alt=""
                    src={linkedin}
                    width="19"
                    height="19"
                    className="d-inline-block align-top"
                  />{' '}</Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='info@enspire-tech.com' >
                  <img
                    alt=""
                    src={mail}
                    width="19"
                    height="19"
                    className="d-inline-block align-top"
                  />{' '}</Nav.Link>
              </Nav.Item>
            </Navbar>
          </Col>

        <Row style={{margin:"1rem", boxShadow: 'inset 0px 1px 0px rgba(255, 255, 255, 0.1)'}}>

          <Nav.Item >
                <Nav.Link href='/' style={{color:'#747777'}}>©2021 Enspire Technology</Nav.Link>
          </Nav.Item>
          <Nav.Item >
                <Nav.Link href='/about' style={{color:'#747777'}}>All rights reserved</Nav.Link>
          </Nav.Item>
        </Row>

      </Navbar>
    );
  }