export default [
    {
        cards: {
            title1:"Amazon web services (AWS) ",
            title2:"Architecting the Future of Your Business Today",
            title3:"Develop and Innovate Faster With Serverless Application Architecture from AWS",
            title4:"Develop and Innovate Faster With Serverless Application Architecture from AWS",
            text1:"Harness the power of the cloud. Wherever you are in the cloud migration process, Enspire Technolgies’ cloud computing expertise helps you get the most out of your Amazon Web Services (AWS) to help you be more nimble and work faster at lower cost.",
            text2:"Migrate your business to the cloud with AWS. We can architect that journey. See faster benefits from your hybrid or full cloud migration with a proven approach to migrating your data and workloads to AWS.",
            text3:"Free your business from the task-oriented responsibilities associated with server management and provisioning.  Save time and reduce costs.",
            text4:"Free your business from the task-oriented responsibilities associated with server management and provisioning.  Save time and reduce costs.",
            image1:"https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-533@2x.svg",
            image2:"https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-533@2x.png",
            image3:"https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-533-1@2x.png",
            image4:"https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-533-2@2x.png"
        }
    }
]