import { Card, CardDeck, CardColumns, Table, Container } from 'react-bootstrap';
import React from 'react';
import'./cards.scss';

export default function Cards (props){
    const { title1, title2, title3, title4, text1, text2, text3, text4, image1, image2, image3, image4 } = props;

    return(
            <CardDeck style={{width:'100%'}}>
                <Card style={{ marginRight: '5px', background:'none'}}>
                    <Card.Img variant="top" src={image1} />
                    <Card.Body>
                        <Card.Title>{title1}</Card.Title>
                        <Card.Text>{text1}</Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ marginRight: '5px', background:'none'}}>
                    <Card.Img variant="top" src={image2} />
                    <Card.Body>
                        <Card.Title>{title2}</Card.Title>
                        <Card.Text>{text2}</Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ marginRight: '5px', background:'none'}}>
                    <Card.Img variant="top" src={image3} />
                    <Card.Body>
                        <Card.Title>{title3}</Card.Title>
                        <Card.Text>{text3}</Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{ marginRight: '5px', background:'none'}}>
                    <Card.Img variant="top" src={image4} />
                    <Card.Body>
                        <Card.Title>{title4}</Card.Title>
                        <Card.Text>{text4}</Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>

    )
}