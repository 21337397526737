import React from 'react';
import './jumboImageCardRight.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col, Card } from 'react-bootstrap'
import borderedimage from '../../img/borderedimage.png';

export default function JumboImageCardRight(props) {
    const { image, rectangle530, rectangle531, text, text9, text10, maskGroup } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage" style={{maxHeight:"1200px"}}>
             <Image className="left-bg-stripe" src={image} />
                <Container>
                    <TitleStripe 
                        title={text}
                        stripe={rectangle530}
                    />
                    
                
                    <Row className="description">
                        <Col >{text9}</Col>
                        <Col ></Col>
                    </Row>

                    <Row >
                        <Col >
                            <Image fluid className="panup" src={borderedimage} />
                        </Col>
                        <Col>
                        <Image fluid className="recipe-card-flair" src={maskGroup} />
                        <Card className="recipe-card">
                            <Card.Body>
                                <Card.Title>Recipe for success</Card.Title>
                                <Card.Title>1. Draw</Card.Title>
                                <Card.Text>Build your workflow applications using drag and drop editing tools</Card.Text>
                                <Card.Title >2. Publish</Card.Title>
                                <Card.Text>Package all elements of the application and manage version control</Card.Text>
                                <Card.Title >3. Engage</Card.Title>
                                <Card.Text>Distribute the application to run in the cloud or on any device, platform, or channel</Card.Text>
                            </Card.Body>
                        </Card>
                        </Col>
                    </Row>
                </Container>
    </Jumbotron>
    )
}