import React from 'react';
import "../../components/App/App.scss";
import Hero from "../../components/hero/hero";
import ContactUs from '../../components/contactUs/contactUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { awsPageData } from './awsPageData';
import JumboImageTopRight from '../../components/jumboImageTopRight/jumboImageTopRight';
import JumboDemoImage from '../../components/jumboDemoImage/jumboDemoImage';
import Helmet from 'react-helmet';

export default function Aws() {
    
    return (

        <Container fluid className="hero-container">
            
            <Helmet>
                <title>AWS Expertise</title>
                <meta 
                    name="description"
                    content="The power of Enterprise-Level Technology at your fingertips"
                 />
                 <meta 
                    name="keywords"
                    content="ipaas, boomi, enspire, technologies, integration resources, integration platform as a service, connections, intelligent engineering, 
                    cloud computing, severless, serverless application architecture, aws cloud architecure, cloud architecture, integration support, integration development, api management"
                 />
            </Helmet>

            {awsPageData.map(data => (
              <Hero
                getDemoButton={data.hero.getDemoButton}
                text7={data.hero.text7}
                rectangle520={data.hero.rectangle520}
                text5={data.hero.text5}
                text6={data.hero.text6}
              />
            ))}

            {awsPageData.map(data => (
                <JumboImageTopRight
                    image = {data.jumboImageTopRight.image}
                    rectangle530 ={data.jumboImageTopRight.rectangle530}
                    rectangle531 = {data.jumboImageTopRight.rectangle531}
                    text = {data.jumboImageTopRight.text}
                    text9 = {data.jumboImageTopRight.text9}
                    text10 = {data.jumboImageTopRight.text10}
                    maskGroup = {data.jumboImageTopRight.maskGroup}
                />
            ))}

            {awsPageData.map(data => (
                <ContactUs
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
            ))}

            <div style={{backgroundImage: 'url(rectangle)',backgroundSize: '100%', width:'100%', backgroundAttachment: 'fixed'}}>
                {awsPageData.map(data => (
                    <JumboDemoImage
                        rectangle530 = {data.jumboDemoImage.rectangle530}
                        text = {data.jumboDemoImage.title}
                        rectangle531={data.jumboDemoImage.rectangle5303}
                        text18={data.jumboDemoImage.text18}
                        text10={data.jumboDemoImage.text10}
                    />
                ))}
            
                {awsPageData.map(data => (
                <ContactUs 
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
                ))}
            </div>
        </Container>
    );
}
