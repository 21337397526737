export const awsPageData = [
    {
        hero:{
            getDemoButton: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/buttons-orange@2x.svg",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-2@2x.svg",
            text5: "AWS Expertise",
            text6: <h3>The Power of Enterprise-Level Technology at Your Finger Tips <br /> <br /> <h5>Enspire Technologies’ AWS expertise delivers best-in-class design, development, and support for your cloud architecture and managed services. </h5></h3>
         },
        jumboImageTopRight:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: <>AWS Cloud <br /> Architecture</>,
            text9: "We offer expertise on the world’s most reliable, secure, and capable cloud. We help you migrate your mission-critical applications completely from on-premises to cloud, or any using any hybrid model in between.Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, we have the right solution for you.",
            text10: "We provide high quality work and customized solutions to ensure successful outcomes for your people and your business.",
            rectangle531: " ../../img/panupskyscraper",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg"
        },
        jumboDemoImage:{
            title: "Serverless Application Architecture",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            rectangle5303: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-2@2x.svg",
            text18: <>Cloud migration and hybridization<br />for a modern business</>,
            text10: "Let us manage your server needs with AWS. We can help build and run your applications so that your developers can focus on your own core products.",
            rightStrategyPagesShadowRight: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/right-strategy-pages-shadow-right@1x.svg",
            
        },
        contactUs2Props: {
            overlapGroup3: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/black-orange-no@1x.svg",
            text11: "Contact us for a demo",
            text21: "Discover the how Enspire Technologies’ technical experts will help you transform your business.",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-1@2x.svg"
        }
    }
];