import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Button from '../button/button';
import buttonData from '../button/buttonData';
import './hero.scss';
import flair from '../../img/heroFlair.png'

export default function Hero (props){

    const { text5, text6 } = props;

    return(
        
        <Container  className="hero-section" >
            
            <Row className="title">
                <Col xs={1} sm={1} md={1} lg={1} style={{padding:"0px"}}>
                    <Image className="hero-flair" src={flair} />   
                </Col>

                <Col>
                    <h1>{text5}</h1>
                </Col>
            </Row>

            <Row>
                <Col xs={2} sm={1} md={1} lg={1} xs={1}/>
                <Col>
                <h4 className="text-6">{text6}</h4>
                {buttonData.map(data => (
                    <Button 
                        rectangle520={data.rectangle520}
                        getDemoButton={data.getDemoButton}
                        text7={'Get Demo'}
                    />
                ))}
                </Col>
            </Row>  
        </Container>
    )
}
