import React from 'react';
import './jumboImageTopRight.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col } from 'react-bootstrap'
import binary from '../../img/binary.jpg';
import cloud from '../../img/cloud.jpg';

export default function JumboImageTopRight(props) {
    const { image, rectangle530, text, text9, text10, maskGroup } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage">
             <Image className="left-bg-stripe" src={image} />
                <Container>
                    <Row style={{position:'relative'}}>
                        <Col>
                            <TitleStripe 
                                title={text}
                                stripe={rectangle530}
                            />
                        
                            <Row className="description">
                                <Col xs={6} md={5} lg={5}>{text9}</Col>
                            </Row>

                            <Row >
                                <Col className="binary">
                                    <Image fluid src={binary} />
                                    <Image fluid className="image-flair" src={maskGroup} />
                                </Col>
                            </Row>
                        </Col>
                        <Image rounded className="cloud" src={cloud} />
                    </Row>
                </Container>
    </Jumbotron>
    )
}