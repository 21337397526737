import React from 'react';
import './jumbotronFourCards.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col, CardDeck, Card } from 'react-bootstrap'
import Cards from '../Scrollcards/cards';

export default function JumbotronFourCards(props) {
    const { image, rectangle530, rectangle531, title, text10, maskGroup, title1, title2, title3, title4, text1, text2, text3, text4 } = props;
  
    return (
        <Jumbotron fluid className="jumboFourCards" >
            <Container >

                <Image className="mask-group-1" src={image} />
                
                <TitleStripe 
                    title={title}
                    stripe={rectangle530}
                />

                <Row>
                    <Col>
                        <h1 className="subtitle">Get Connected and Stay Connected to Drive Your Business Forward</h1>
                        <div className="text-10">{text10}</div>
                    </Col>
                </Row>

            
            <CardDeck style={{color:"black"}}>
            <Card style={{background:'white'}}>
                    <Card.Body>
                        <Card.Title>{title1}</Card.Title>
                        <Card.Text>{text1}</Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{background:'white'}}>
                    <Card.Body>
                        <Card.Title>{title2}</Card.Title>
                        <Card.Text>{text2}</Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{background:'white'}}>
                    <Card.Body>
                        <Card.Title>{title3}</Card.Title>
                        <Card.Text>{text3}</Card.Text>
                    </Card.Body>
                </Card>

                <Card style={{background:'white'}}>
                    <Card.Body>
                        <Card.Title>{title4}</Card.Title>
                        <Card.Text>{text4}</Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            </Container>
    </Jumbotron>
    )
}