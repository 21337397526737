import React from 'react';
import "../../components/App/App.scss";
import Hero from "../../components/hero/hero";
import ContactUs from '../../components/contactUs/contactUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import panupskyscraper from '../../img/panupskyscraper.png';
import { aboutPageData } from './aboutPageData';
import JumbotronThreeCards from '../../components/jumboThreeCards/jumboThreeCards';
import JumbotronImageCardFront from '../../components/jumboImageCardFront/jumboImageCardFront';
import JumbotronPresidentProfile from '../../components/jumboPresidentProfile/jumboPresidentProfile';
import Helmet from 'react-helmet';

export default function About() {
    
    return (

        <Container fluid className="hero-container">
            
            <Helmet>
                <title>About Enspire</title>
                <meta 
                    name="description"
                    content="Connecting the right people with the right data at the right time"
                 />
                 <meta 
                    name="keywords"
                    content="ipaas, boomi, enspire, technologies, integration, integration platform as a service, connections, intelligent engineering, 
                    cloud computing, cloud architecture, support, integration development, api management, mission statement, breaking silos, president, founder, contact"
                 />
            </Helmet>

            {aboutPageData.map(data => (
              <Hero
                getDemoButton={data.hero.getDemoButton}
                text7={data.hero.text7}
                rectangle520={data.hero.rectangle520}
                text5={data.hero.text5}
                text6={data.hero.text6}
              />
            ))}


            {aboutPageData.map(data => (
                <JumbotronImageCardFront
                    image = {data.jumbotronImageCardFront.image}
                    rectangle530 ={data.jumbotronImageCardFront.rectangle530}
                    rectangle531 = {panupskyscraper}
                    text = {data.jumbotronImageCardFront.text}
                    text9 = {data.jumbotronImageCardFront.text9}
                    text10 = {data.jumbotronImageCardFront.text10}
                    maskGroup = {data.jumbotronImageCardFront.maskGroup}
                />
            ))}

            {aboutPageData.map(data => (
                <ContactUs
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
            ))}

            {aboutPageData.map(data => (
                <JumbotronThreeCards
                    image = {data.jumboThreeCards.image}
                    rectangle530 ={data.jumboThreeCards.rectangle530}
                    rectangle531 = {data.jumboThreeCards.rectangle531}
                    text8 = {data.jumboThreeCards.text14}
                    title = {data.jumboThreeCards.title}
                    text9 = {data.jumboThreeCards.text9}
                    text10 = {data.jumboThreeCards.text10}
                    maskGroup = {data.jumboThreeCards.maskGroup}
                    title1 = {data.jumboThreeCards.cardDeck.title1}
                    title2 = {data.jumboThreeCards.cardDeck.title2}
                    title3 = {data.jumboThreeCards.cardDeck.title3}
                    title4 = {data.jumboThreeCards.cardDeck.title4}
                    text1 = {data.jumboThreeCards.cardDeck.text1}
                    text2 = {data.jumboThreeCards.cardDeck.text2}
                    text3 = {data.jumboThreeCards.cardDeck.text3}
                    text4 = {data.jumboThreeCards.cardDeck.text4}
                />
            ))}


            <div style={{backgroundImage: 'url(rectangle)',backgroundSize: '100%', width:'100%', backgroundAttachment: 'fixed'}}>
                {aboutPageData.map(data => (
                    <JumbotronPresidentProfile
                        rectangle530 = {data.jumboPresidentProfile.rectangle530}
                        text = {data.jumboPresidentProfile.title}
                        rectangle531={data.jumboPresidentProfile.rectangle5303}
                        text18={data.jumboPresidentProfile.text18}
                    />
                ))}
            
                {aboutPageData.map(data => (
                <ContactUs 
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
                ))}
            </div>
        </Container>
    );
}
