import React from 'react';
import "../../components/App/App.scss";
import Hero from "../../components/hero/hero";
import ContactUs from '../../components/contactUs/contactUs';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import JumbotronImage from '../../components/jumbotronImage/jumbotronImage';
import { homePageData } from './homePageData';
import JumbotronFourCards from '../../components/jumbotronFourCards/jumbotronFourCards';
import JumboScrollCards from '../../components/jumboScrollCards/jumboScrollCards';
import JumboAltHeightCards from '../../components/jumboAltHeightCards/jumboAltHeightCards';
import Helmet from 'react-helmet';

export default function Home() {
    
    return (

        <Container fluid className="hero-container">
            
            <Helmet>
                <title>Enspire</title>
                <meta 
                    name="description"
                    content="Integrate, analyze, understand and master your data."
                 />
                 <meta 
                    name="keywords"
                    content="ipaas, boomi, enspire, technologies, integration resources, integration platform as a service, connections, intelligent engineering, 
                    cloud computing, strategy, contact us, aws, integration support, boomi flow, boomi connect now, boomi api management"
                 />
            </Helmet>

            {homePageData.map(data => (
              <Hero
                getDemoButton={data.hero.getDemoButton}
                text7={data.hero.text7}
                rectangle520={data.hero.rectangle520}
                text5={data.hero.text5}
                text6={data.hero.text6}
              />
            ))}


            {homePageData.map(data => (
                <JumbotronImage 
                image = {data.jumbotronImage.image}
                rectangle530 ={data.jumbotronImage.rectangle530}
                rectangle531 = {data.jumbotronImage.rectangle531}
                text = {data.jumbotronImage.text}
                text9 = {data.jumbotronImage.text9}
                text10 = {data.jumbotronImage.text10}
                maskGroup = {data.jumbotronImage.maskGroup}
                />
            ))}

            {homePageData.map(data => (
                <ContactUs
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
            ))}

            {homePageData.map(data => (
                <JumbotronFourCards
                    image = {data.jumboFourCards.image}
                    rectangle530 ={data.jumboFourCards.rectangle530}
                    rectangle531 = {data.jumboFourCards.rectangle531}
                    text8 = {data.jumboFourCards.text14}
                    title = {data.jumboFourCards.title}
                    text9 = {data.jumboFourCards.text9}
                    text10 = {data.jumboFourCards.text10}
                    maskGroup = {data.jumboFourCards.maskGroup}
                    title1 = {data.jumboFourCards.cardDeck.title1}
                    title2 = {data.jumboFourCards.cardDeck.title2}
                    title3 = {data.jumboFourCards.cardDeck.title3}
                    title4 = {data.jumboFourCards.cardDeck.title4}
                    text1 = {data.jumboFourCards.cardDeck.text1}
                    text2 = {data.jumboFourCards.cardDeck.text2}
                    text3 = {data.jumboFourCards.cardDeck.text3}
                    text4 = {data.jumboFourCards.cardDeck.text4}
                />
            ))}


            <div className="" style={{backgroundImage: 'url(rectangle)',backgroundSize: '100%', width:'100%', backgroundAttachment: 'fixed'}}>
                {homePageData.map(data => (
                    <JumboScrollCards
                        rectangle530 ={data.jumboScrollCards.rectangle530}
                        title = {data.jumboScrollCards.title}
                        rectangle5303={data.jumboScrollCards.rectangle5303}
                        text18={data.jumboScrollCards.text18}
                        line13={data.jumboScrollCards.line13}
                        line14={data.jumboScrollCards.line14}
                        rightStrategyPagesShadowRight={data.jumboScrollCards.rightStrategyPagesShadowRight}
                    />
                ))}

                {homePageData.map(data => (
                    <JumboAltHeightCards
                        image2={data.whyEnspire.image2}
                        rectangle5304={data.whyEnspire.rectangle5304}
                        text20={data.whyEnspire.text20}
                        title={data.whyEnspire.title}
                        stripe={data.whyEnspire.stripe}
                        title1 = {data.whyEnspire.cardDeck.title1}
                        title2 = {data.whyEnspire.cardDeck.title2}
                        title3 = {data.whyEnspire.cardDeck.title3}
                        title4 = {data.whyEnspire.cardDeck.title4}
                        text1 = {data.whyEnspire.cardDeck.text1}
                        text2 = {data.whyEnspire.cardDeck.text2}
                        text3 = {data.whyEnspire.cardDeck.text3}
                        text4 = {data.whyEnspire.cardDeck.text4}
                    />
                ))}
            
                {homePageData.map(data => (
                <ContactUs 
                    overlapGroup2={data.contactUs2Props.overlapGroup3}
                    text11={data.contactUs2Props.text11}
                    text12={data.contactUs2Props.text21}
                    text7={data.contactUs2Props.text7}
                    rectangle520={data.contactUs2Props.rectangle520}
                />
                ))}
            </div>
        </Container>
    );
}
