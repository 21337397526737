export const boomiPageData = [
    {
        hero:{
            getDemoButton: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/buttons-orange@2x.svg",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-2@2x.svg",
            text5: <h1 style={{marginLeft:"3vw"}}>Boomi</h1>,
            text6: <h3>Your data has never been more important. Drive productivity, reduce costs and increase business performance with full data integration and rapid analysis.</h3>
         },
        jumboTextRight:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: <>Integrated<br />Experiences Delivered</>,
            text9: "Enspire Technologies meets your connectivity needs with powerful, easy to use tools from Boomi.",
            text10: "Boomi Connect Now offers a host of pre-built integrations for the non-technical user to complete on demand integrations.",
            text11:"Build and automate workflows, package the application, and distribute via the cloud with Boomi Flow.",
            text12:"Boomi Integration allows you to integrate applications and data to connect your business.",
            text13:"Easily manage every aspect of the API lifecycle with Boomi API management. ",
            rectangle531: " ../../img/panupskyscraper",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg"
        },
        content2: {
            rectangle5302: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-1@2x.svg",
            text14: "Right strategy, right time integration",
            text15: "Get Connected and Stay Connected to Drive Your Business Forward",
            text16: "As a Boomi Certified Partner, Enspire Technologies brings broad and deep expertise in the customization and implementation of the best integration platform on the market. We deliver intelligent engineering with white glove customer service in delivery of the full Boomi technical stack.",
            text9: "Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, we have the right solution for you.",
            text10: "We provide high quality work and customized solutions to ensure successful outcomes for your people and your business.",
            rectangle531: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-531@1x.png",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg",
            card1:{
                title:<>Boomi<br />Connect Now</>,
                description:"Get the unmatched integration capabilities of Boomi with the ease of self-service."
            },
            card2:{
                title:"Boomi Flow",
                description:"Create a guided journey for your customers with simple workflow automation and mobile application development using a low-code development solution that allows your business to be more agile and deliver a better customer experience."
            }
        },
        jumboImageCardRight:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: <>Providing the Best Solutions<br />for Your Business Challenges</>,
            text9: "Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, we have the right solution for you.",
            text10: "As a Boomi Certified Partner, Enspire Technologies brings broad and deep expertise in the customization and implementation of the best integration platform on the market. We deliver intelligent engineering with white glove customer service in delivery of the full Boomi technical stack.",
            rectangle531: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-531@1x.png",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg",
            title: <>Build and Automate Workflows, <br /> From Simple to Sophisticated Applications</>,
            cardDeck: {
                title1:"Integration",
                title2:"Cloud Computing",
                title3: "Intelligent Engineering",
                text1: "We break down silos and connect your critical business processes to meet your business goals. We have deep experience with a wide range of tools and technologies.",
                text2: "Whether you’re looking for cutting edge cloud architecture or serverless application architecture, we meet you where you are and guide you on your journey.",
                text3: "We listen to your needs, closely collaborate with your teams, and develop the best solution for your problem. We believe there are no ‘one size fits all’ solutions. ",
            }
        },
        jumboImageTopLeft:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: "Connections, Accelerated",
            text9: "Boomi Flow is a modern, cloud-native service for creating customer journeys and automating simple and sophisticated workflows that accelerate your business outcomes.",
            text10: "We are a Boomi certified partner with expertise in the full Boomi technology stack, and architected Boomi Connect Now.  We also have deep experience implementing Amazon Web Services (AWS) to modernize your business processes.",
            text11:"Build and automate workflows, package the application, and distribute via the cloud with Boomi Flow.",
            text12:"Build and automate workflows, package the application, and distribute via the cloud with Boomi Flow.",
            text13:"Easily manage every aspect of the API lifecycle with Boomi API management. ",
            rectangle531: " ../../img/panupskyscraper",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg",
            cardDeck: {
                title1:"Integration",
                title2:"Cloud Computing",
                title3: "Intelligent Engineering",
                text1: "We break down silos and connect your critical business processes to meet your business goals. We have deep experience with a wide range of tools and technologies.",
                text3: "Whether you’re looking for cutting edge cloud architecture or serverless application architecture, we meet you where you are and guide you on your journey.",
                text2: "We listen to your needs, closely collaborate with your teams, and develop the best solution for your problem. We believe there are no ‘one size fits all’ solutions. ",
            }
        },
        contactUs2Props: {
            overlapGroup3: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/black-orange-no@1x.svg",
            text11: "Contact us for a demo",
            text21: "Discover the how Enspire Technologies’ technical experts will help you transform your business.",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-1@2x.svg"
        },
        jumboLogo: {
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: "Customers Using API Management",
        },
        jumboAltHeight: {
            image2: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle5304: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-1@2x.svg",
            title: "Integration Resources",
            text9:"Our over 12,000 customers span across all industries – from research/education to technology to healthcare, see how they accelerate outcomes and reduce manual work through Boomi’s low-code integration platform.",
            stripe: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            cardDeck: {
                title1:'Vidyard',
                title2:"Link",
                title3: "Link",
                title4: ".Pdf",
                title5: "Link",
                text1: <>Boomi & International Justice Mission <br /> The world's largest anti-slavery organization leverages Boomi</>,
                text2: "Realize Your Digital Ambition with Boomi Blueprint Framework",
                text3: "Boomi Ranked a ‘Market Leader’ in New Ovum Report on Hybrid Integration",
                text4: "Comprehensive HR Integration Drives Rapid Growth for Dropbox",
                text5: "From There to Here and Here to There, Data is Everywhere. Say! How Do We Integrate?"
            }
        }
    }
];