import React from 'react';
import './jumboThreeCards.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col, CardDeck, Card } from 'react-bootstrap'

export default function JumbotronThreeCards(props) {
    const { image, rectangle530,  title, text10, title1, title2, title3, text1, text2, text3 } = props;
  
    return (
        <Jumbotron fluid className="jumboFourCards" >
            <Container >

                <Image className="mask-group-1" src={image} />
                
                <TitleStripe 
                    title={title}
                    stripe={rectangle530}
                />

                <Row>
                    <Col>
                        <h1 className="subtitle">Get Connected and Stay Connected to Drive Your Business Forward</h1>
                        <div className="text-10">{text10}</div>
                    </Col>
                </Row>

            
            <CardDeck style={{color:"black" }}>

                <Card className="cards-three" style={{background:'white'}}>
                    <Card.Body>
                        <Card.Title>
                            <h1 style={{color:"#EE8F39"}}>01</h1>
                            {title1}
                        </Card.Title>
                        <Card.Text>{text1}</Card.Text>
                    </Card.Body>
                </Card>

                <Card className="cards-three" style={{background:'transparent', color:'white'}}>
                    <Card.Body>
                    <Card.Title>
                            <h1 style={{color:"#EE8F39"}}>02</h1>
                            {title2}
                        </Card.Title>
                        <Card.Text>{text2}</Card.Text>
                    </Card.Body>
                </Card>

                <Card className="cards-three" style={{background:'transparent', color:'white'}}>
                    <Card.Body>
                    <Card.Title>
                            <h1 style={{color:"#EE8F39"}}>03</h1>
                            {title3}
                        </Card.Title>
                        <Card.Text>{text3}</Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            </Container>
    </Jumbotron>
    )
}