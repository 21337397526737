import React from 'react';
import './jumboPresidentProfile.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col } from 'react-bootstrap'
import Bryan from '../../img/Bryan.Williams.JPG';

export default function JumbotronPresidentProfile(props) {
    const { image, rectangle530, text, text9, text10 } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage">
             <Image className="left-bg-stripe" src={image} />
                <Container>
                    <TitleStripe 
                        title={text}
                        stripe={rectangle530}
                    />
                    
                    <Row className="description">
                        <Col xs={1} md={1} lg={1} />
                        <Col xs={6} md={5} lg={5}>{text9}</Col>
                        <Col xs={4} md={5} lg={5}>{text10}</Col>
                    </Row>

                    <Row className="profile">
                        <Col style={{position:'relative'}}>
                            <Image fluid rounded src={Bryan} />
                        </Col>
                        <Col className="text-wrap">
                            <h1 style={{borderBottom:'orange 1px solid'}}> Bryan Williams </h1>
                            <h5>
                                Bryan Williams is the president and founder of Enspire Technologies whose mission is to use intelligent engineering to provide the best solution for your data challenges. He has a solid background in architecting and engineering software and is the architect of Boomi Connect Now. Bryan brings the full depth of his IT experience to the table for every customer.
                            </h5>
                        </Col>
                    </Row>
                </Container>
    </Jumbotron>
    )
}
