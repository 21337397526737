import React from 'react';
import './jumboImageTopLeft.scss';
import TitleStripe from '../titleStripe/titleStripe';
import {Jumbotron, Image, Container, Row, Col, Card, CardDeck } from 'react-bootstrap'
import cardfiller from '../../img/card-filler.png';
import mirror from '../../img/mirror.png';

export default function JumboImageTopLeft(props) {
    const { rectangle530, text, text9, text1, text2, text3 } = props;
  
    return (
        <Jumbotron fluid  className="jumboImage">
            
            <Container style={{position:'relative'}}>
                <Row>

                    <Col className="image-left" xs={4} m={4} lg={4}>
                        <Image fluid rounded src={mirror} />
                    </Col>

                    <Col xs={2} m={2} lg={2} />

                    <Col xs={6} m={6} lg={6}>
                            <TitleStripe 
                                title={text}
                                stripe={rectangle530}
                            />
                            
                            <Row className="description">
                                <Col >{text9}</Col>
                            </Row>

                            <Row >
                                <CardDeck style={{color:"black" }}>
                                    <Row style={{paddingBottom: '2rem'}}>
                                        <Col>
                                            <Card className="cards-three" style={{background:'transparent', color:'white'}}>
                                                <Card.Body>
                                                    <Card.Title style={{color:"#EE8F39"}}>Integration</Card.Title>
                                                    <Card.Text>{text1}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                        <Col>
                                            <Card className="cards-three" style={{background:'white', color:'black'}}>
                                                <Card.Body>
                                                    <Card.Title>Intelligent Engineering</Card.Title>
                                                    <Card.Text>{text2}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row >
                                        <Col >
                                            <Card className="cards-three" style={{background:'white', color:'black'}}>
                                                <Card.Body>
                                                    <Card.Title>Cloud Computing</Card.Title>
                                                    <Card.Text>{text3}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Col className="card-filler-flair">
                                                <Image style={{maxHeight:"171px"}} src={cardfiller} />
                                            </Col>
                                        </Col>
                                    </Row>
                                </CardDeck>
                            </Row>
                    </Col>

                </Row>
            </Container>
    </Jumbotron>
    )
}