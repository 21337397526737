import React from "react";
import "./navBar.scss";
import { Nav, Navbar, Image, Row, Col } from 'react-bootstrap';


export default function NavBar() {

    return (
      <Navbar collapseOnSelect className="nav-bar" expand="sm" sticky='top'>
        
        <Row >
          <Col  style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', }}xs={4} lg={2}
          >
            <Image className="enspire-technologies-logo-01-h" src="https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/enspire-technologies-logo-01-4@2x.png" />
          </Col>
          <Col className="main-menu" xs={8} lg={10}>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse >
              <Nav.Item >
                <Nav.Link href='/' >Home</Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='/about' >About</Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='/contact' >Contact</Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='/boomi' >Boomi</Nav.Link>
              </Nav.Item>
              <Nav.Item >
                <Nav.Link href='/aws' >AWS Expertise</Nav.Link>
              </Nav.Item> 
            </Navbar.Collapse>
          </Col>
          </Row>
      </Navbar>
    );
  }