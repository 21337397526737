export const homePageData = [
    {
        hero:{
            getDemoButton: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/buttons-orange@2x.svg",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-2@2x.svg",
            text5: <>Integrate, analyze, understand,<br />and master your data. <br />Grow your business.</>,
            text6: "Your data is the most powerful tool in your tool belt. Put it to work for you with full data integration and comprehensive analysis driven by modern technology solutions customized for your business by Enspire Technologies.",
        },
        jumbotronImage:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: <>Providing the Best Solutions<br />for Your Business Challenges</>,
            text9: "Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, we have the right solution for you.",
            text10: "We provide high quality work and customized solutions to ensure successful outcomes for your people and your business.",
            rectangle531: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-531@1x.png",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg"
        },
        content2: {
            rectangle5302: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-1@2x.svg",
            text14: "Right strategy, right time integration",
            text15: "Get Connected and Stay Connected to Drive Your Business Forward",
            text16: "As a Boomi Certified Partner, Enspire Technologies brings broad and deep expertise in the customization and implementation of the best integration platform on the market. We deliver intelligent engineering with white glove customer service in delivery of the full Boomi technical stack.",
            text9: "Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, we have the right solution for you.",
            text10: "We offer intelligent engineering with expertise on the full Dell Boomi technical stack.",
            rectangle531: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-531@1x.png",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg",
            card1:{
                title:<>Boomi<br />Connect Now</>,
                description:"Get the unmatched integration capabilities of Boomi with the ease of self-service."
            },
            card2:{
                title:"Boomi Flow",
                description:"Create a guided journey for your customers with simple workflow automation and mobile application development using a low-code development solution that allows your business to be more agile and deliver a better customer experience."
            }
        },
        jumboFourCards:{
            image: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            text: <>Providing the Best Solutions<br />for Your Business Challenges</>,
            text9: "Whether you need to integrate NetSuite and Salesforce, move your data into Microsoft Dynamics GP, or move any data from one place to another and comprehensively analyze it, Enspire Technologies has the right solution for you.",
            text10: "We provide high quality work and customized solutions to ensure successful outcomes for your people and your business.",
            rectangle531: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-531@1x.png",
            maskGroup: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/mask-group@2x.svg",
            title: "Right strategy, right time integration",
            cardDeck: {
                title1:<>Boomi<br />Connect Now</>,
                title2:"Boomi Flow",
                title3: <>Boomi<br />Integration</>,
                title4: <>Boomi API<br />Management</>,
                text1: "Get the unmatched integration capabilities of Boomi with the ease of self-service.",
                text2: "Create a guided journey for your customers with simple workflow automation and mobile application development using a low-code development solution that allows your business to be more agile and deliver a better customer experience.",
                text3: "Connecting your critical business processes is crucial. Accelerate productivity, results, and ROI with the streamlined processes and simplified interactions made possible with unified data integration.",
                text4: "Your connected business strategy is not complete without comprehensive end-to-end API management and integration. Boomi API Management solves the business problem of strategically designing, tracking, and scaling APIs enterprise-wide."
            }
        },
        jumboScrollCards:{
            title: <>Cloud migration and hybridization <br />for a modern business</>,
            rectangle530: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            rectangle5303: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-2@2x.svg",
            text18: <>Cloud migration and hybridization<br />for a modern business</>,
            rightStrategyPagesShadowRight: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/right-strategy-pages-shadow-right@1x.svg",
            
        },
        contactUs2Props: {
            overlapGroup3: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/black-orange-no@1x.svg",
            text11: "Contact us for a demo",
            text21: "Discover the how Enspire Technologies’ technical experts will help you transform your business.",
            text7: "Get Demo",
            rectangle520: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-520-1@2x.svg"
        },
        whyEnspire: {
            image2: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/image@2x.png",
            rectangle5304: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530-1@2x.svg",
            title: <>Why Enspire<br />Technologies?</>,
            stripe: "https://anima-uploads.s3.amazonaws.com/projects/608c660520169a1bb3180ff5/releases/608c6b94f68e88411d2707bb/img/rectangle-530@2x.svg",
            cardDeck: {
                title1:<>Technical Excellence<br /> With Proven Results</>,
                title2:"The Right Tool for the Job",
                title3: <>White Glove Service<br />Tailored to Your Business Needs</>,
                title4: <>Nimble Execution for<br />Faster Time-to-Market</>,
                text1: "Our expertise is backed by more than two decades of experience administering, integrating, and business run smoother so that you can focus on growing that business.",
                text2: "Our highly skilled and experienced team has a wide range of tools at their disposal to help you solve your most complex business problems.",
                text3: "We listen to you to understand your unique challenges in order to architect the best solutions for your business. We are in the  business of making your business better.",
                text4: "We customize our approach to your business problems and have the ability to quickly and effectively respond to the ever-changing demands of the market."
            }
        }
    }
];