import React from 'react';
import "../../components/App/App.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import ContactForm from '../../components/contact/contact';

export default function Contact() {
    
    return (

        <Container fluid className="hero-container" style={{color:'white'}}>
            
            <ContactForm  />

        </Container>
    );
}
