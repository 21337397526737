import './titleStripe.scss';
import { Row, Col } from 'react-bootstrap' 

export default function TitleStripe(props){
    const { title } = props;

    return (
        <Row className="title">
                <svg xmlns="http://www.w3.org/2000/svg" className="h1flair" width="42" height="42" viewBox="0 0 42 42" fill="none">
                    <path d="M29.4126 0.220751L41.8541 0.220802L0.220802 41.8541L0.220794 29.4125L29.4126 0.220751Z" fill="#EE8F39"/>
                </svg>

            <Col>
                <h1 className="text-8">{title}</h1>
            </Col>
        </Row>
    )
}