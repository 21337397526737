import React from 'react';
import { Container, Button, Row, Col, Image } from 'react-bootstrap';
import './contact.scss'
import flair from '../../img/heroFlair.png'

const ContactForm = () => {
  return (
    <Container className="contact-page" >
      <Row style={{marginBottom:"5rem",fontWeight:"bold"}}>
        <Col xs={1} sm={1} md={1} lg={1} style={{padding:"0px"}}>
            <Image className="hero-flair" src={flair} />   
        </Col>
        <Col><h1>Enspire Technology</h1></Col>
      </Row>
      <Row style={{marginBottom:"5rem", fontWeight:"bold"}}>
        <h3>Break down silos and connect your data today</h3>
      </Row>
      <Button size="lg" style={{backgroundColor:"#EF8157"}} href="mailto:info@enspire-tech.com">Contact Us</Button>
    </Container>
  );
};

export default ContactForm;