import React from 'react';
import './contactUs.scss';
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import flair from '../../img/button-flair.png';

export default function ContactUs(props) {
    const { text11, text12 } = props;
  
    return (
      <Container>
        <Container className="overlap-group2" >
            <Row >
              <Col >
                <h2>{text11}</h2>
                <div className="text-12">{text12}</div>
              </Col>
            
              <Col size="lg" className="buttonalt" >
                <Button href="/contact" className="get-demo-button-2">
                    Contact Us
                    <Image src={flair} />
                </Button>
              </Col>

            </Row>
        </Container>
      </Container>
    );
  }