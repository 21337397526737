import React from 'react';
import "./App.scss";
import NavBar from "../navBar/navbar"
import { Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import Home from '../../pages/homePage/homePage';
import About from '../../pages/aboutPage/aboutPage';
import Boomi from "../../pages/boomiPage/boomiPage";
import Aws from '../../pages/awsPage/awsPage';
import Contact from '../../pages/contactPage/contactPage';
import Footer from '../footer/footer';

function App() {
  return (
    <Container fluid className="home-page" >
      
      <Row>
        <NavBar/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/boomi" component={Boomi} />
          <Route exact path="/aws" component={Aws} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </Row>
    </Container>
  );
}

export default App;